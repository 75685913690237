<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="location"></span> Städte
            </h3>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid uk-grid-small" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-margin-large-bottom">
          <div class="uk-card-body">
            <div class="uk-grid uk-grid-small uk-flex uk-flex-right uk-flex-middle" data-uk-grid>
              <div>
                <router-link
                  class="uk-button uk-button-primary uk-button-small"
                  :to="{name: 'city-edit'}">
                  <span data-uk-icon="plus-circle"></span>
                  Neu
                </router-link>
              </div>
            </div>
            <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
              <div class="uk-width-auto">
                <form class="uk-search uk-search-default uk-width-medium">
                  <span uk-search-icon></span>
                  <input class="uk-search-input" placeholder="Suchen..." v-model="search" @keydown.enter.prevent="searchCity">
              </form>
              <a href="#" class="uk-icon uk-margin-small-left" data-uk-icon="refresh" @click.prevent="resetSearch"></a>
              </div>
              <div class="uk-width-expand" v-if="meta && meta.pagination">
                <pagination :meta="meta" v-if="meta.pagination.total_pages > 1" class="uk-float-right"></pagination>
              </div>
            </div>
            <hr>
            <table class="uk-table uk-table-hover uk-table-divider has-checkbox">
              <thead>
                <tr>
                  <th class="uk-table-shrink">
                    <div class="uk-button-group checkbox">
                      <div>
                        <label>
                          <input class="uk-checkbox" type="checkbox" @click="selectAll" v-model="allSelected">
                        </label>
                      </div>
                      <button class="uk-button uk-button-small uk-button-default" :disabled="!checked.length">
                        <span uk-icon="icon: triangle-down"></span>
                      </button>
                      <div uk-dropdown="mode: click;animation: uk-animation-slide-top-small;offset: 5">
                        <ul class="uk-nav uk-dropdown-nav">
                          <li>
                            <a class="uk-dropdown-close" href="#" @click.prevent="removeSelected">Löschen</a>
                          </li>
                          <li>
                            <a class="uk-dropdown-close" href="#" @click.prevent="toggleActive">Aktivieren/Deaktivieren</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </th>
                  <th>Name</th>
                  <th class="uk-table-shrink">PLZ</th>
                  <th class="uk-table-shrink">Einwohner</th>
                  <th class="uk-table-shrink">Land</th>
                  <th class="uk-table-shrink uk-text-center">Status</th>
                  <th class="uk-table-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="uk-visible-toggle" v-for="city in list" :key="city.id">
                  <td>
                    <label>
                      <input class="uk-checkbox" type="checkbox" :value="city.id" v-model="checked">
                    </label>
                  </td>
                  <td>
                    <router-link
                      :to="{name: 'city-edit', params: {'id': city.id }}">{{ city.name }}
                    </router-link>
                  </td>
                  <td class="uk-preserve-width">
                    {{ city.zipcode }}
                  </td>
                  <td class="uk-preserve-width">
                    {{ city.population }}
                  </td>
                  <td class="uk-preserve-width">
                    {{ city.country }}
                  </td>
                  <td class="uk-text-center uk-preserve-width">
                    <template v-if="city.active">
                      <span uk-icon="check" class="uk-text-success uk-icon"></span>
                    </template>
                    <template v-else>
                     <span uk-icon="ban" class="uk-text-danger uk-icon"></span>
                    </template>
                  </td>
                  <td class="uk-preserve-width">
                    <ul class="uk-invisible-hover uk-iconnav">
                      <li>
                        <a
                          class="uk-text-danger uk-icon"
                          href="#"
                          uk-icon="icon: trash"
                          uk-tooltip="Löschen"
                          @click="remove(city.id)"
                        ></a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="uk-grid" data-uk-grid>
              <div class="uk-width-expand" v-if="meta && meta.pagination">
                <pagination :meta="meta" v-if="meta.pagination.total_pages > 1" class="uk-float-right"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CityService from '@/services/city.service'
import { mapGetters, mapActions } from 'vuex'
import Pagination from '@/components/Pagination'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
export default {
  data () {
    return {
      checked: [],
      allSelected: false,
      search: ''
    }
  },
  components: {
    Pagination
  },
  mounted () {
    this.getCities()
  },
  computed: {
    ...mapGetters({
      cities: 'city/cities'
    }),
    list () {
      return this.cities.data
    },
    meta () {
      return this.cities.meta
    }
  },
  watch: {
    '$route.query': 'getCities'
  },
  methods: {
    ...mapActions({
      getCities: 'city/getCityList'
    }),
    searchCity () {
      this.getCities(Object.assign({}, { q: this.search }))
    },
    resetSearch () {
      this.search = ''
      this.getCities()
    },
    toggleActive () {
      CityService.toggleStatus(this.checked).then(() => {
        this.checked = []
        this.allSelected = false
        this.getCities()
        iziToast.success({
          title: 'OK',
          message: 'Status aktualisiert!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      }).catch(() => {
        iziToast.error({
          title: 'Fehler',
          message: 'Es ist ein Fehler aufgetreten!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      })
    },
    selectAll () {
      this.checked = []
      if (!this.allSelected) {
        for (const city in this.list) {
          this.checked.push(this.list[city].id.toString())
        }
      }
    },
    removeSelected () {

    }
  }
}
</script>
