<template>
  <ul class="uk-pagination uk-flex-right">
    <li :class="{ 'uk-disabled': meta.pagination.current_page === 1 }">
      <a href="#" @click.prevent="switched(meta.pagination.current_page - 1)">
        <span uk-pagination-previous></span>
      </a>
    </li>
    <li v-if="meta.pagination.current_page >= 4">
      <a href="#" @click.prevent="switched(1)">1</a>
    </li>
    <template v-for="index in meta.pagination.total_pages - 1">
      <li class="uk-disabled" :key="index" v-if="meta.pagination.current_page - 3 - index === 0">
        <span>...</span>
      </li>
      <li
        class="uk-disabled"
        :key="index"
        v-else-if="meta.pagination.current_page + 3 - index === 0"
      >
        <span>...</span>
      </li>
      <template v-else-if="meta.pagination.current_page - 3 - index > 0">
        <div :key="index" hidden></div>
      </template>
      <template v-else-if="meta.pagination.current_page + 3 - index < 0">
        <div :key="index" hidden></div>
      </template>
      <li :key="index" :class="{ 'uk-active': meta.pagination.current_page === index }" v-else>
        <a href="#" @click.prevent="switched(index)">{{ index }}</a>
      </li>
    </template>
    <li :class="{ 'uk-active': meta.pagination.current_page === meta.pagination.total_pages }">
      <a href="#" @click.prevent="switched(meta.pagination.total_pages)">{{
        meta.pagination.total_pages
      }}</a>
    </li>
    <li :class="{ 'uk-disabled': meta.pagination.current_page === meta.pagination.total_pages }">
      <a href="#" @click.prevent="switched(meta.pagination.current_page + 1)">
        <span uk-pagination-next></span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    meta: {
      required: true
    }
  },

  methods: {
    switched (page) {
      if (this.pageIsOutOfBounds(page)) {
        return
      }
      this.$emit('pagination:switched', page)

      this.$router.replace({
        query: Object.assign({}, this.$route.query, { page })
      })
    },

    pageIsOutOfBounds (page) {
      return page <= 0 || page > this.meta.pagination.total_pages
    }
  }
}
</script>
